import React, { Component } from "react"
import * as d3 from "d3"
import { graphql } from "gatsby"
import DatavizCsSankey from "./DataVizCsSankey"

const CsSankeyComponent = ({ data }) => {
  return <DatavizCsSankey data={data} className="DataViz" />
}

export default CsSankeyComponent
