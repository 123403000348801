import React, { Component } from "react"
import { graphql } from "gatsby"

import CsSankeyComponent from "../components/DataVizCsSankeyComponent"
import SEO from "../components/Seo"

const CsSankey = ({ data }) => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <div style={{ backgroundColor: "#6d6c6c" }}>
        <div className="cakes-art">
          <div className="cs-sankey">
            <div className="arts-center">
              <div className="art-block">
                <div className="sketchContainer" id="canvas-parent">
                  <div className="sankeyContainer">
                    <CsSankeyComponent data={data}></CsSankeyComponent>
                    {/* <div id="ca nvasDiv"></div>    */}
                  </div>
                </div>
              </div>
              <div className="sketchDescription">
                <h2 className="sketchTitle" style={{ textAlign: "center" }}>
                  CS Affair
                </h2>
                {/* <p className="sketchDetails">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repudiandae suscipit unde, minima ipsa officia soluta dolor
                  illo nemo optio odio quis, voluptatem vel aliquid itaque?
                  Expedita quae quas, provident doloremque tenetur quidem beatae
                  voluptatum veritatis non dignissimos. Voluptatem ipsum,
                  eligendi possimus obcaecati, maxime eum minima mollitia
                  voluptatibus, doloribus consectetur repellendus. Quia
                  reiciendis impedit vitae numquam libero, placeat id temporibus
                  possimus in dolores, dolor, facere similique. Recusandae, illo
                  soluta! Mollitia maxime, at perspiciatis consectetur vero
                  possimus. Odio praesentium culpa pariatur laboriosam?
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CsSankey

export const query = graphql`
  {
    allFamilyTidyCsv {
      nodes {
        year
        pref
        store
        numStore
      }
    }
    allLawsonTidyCsv {
      nodes {
        year
        pref
        store
        numStore
      }
    }
    allSevenTidyCsv {
      nodes {
        year
        pref
        store
        numStore
      }
    }
    allRegionCsv {
      nodes {
        pref
        region
      }
    }
  }
`
