import * as d3 from "d3"
import D3blackbox from "d3blackbox"
import {
  sankey as d3Sankey,
  sankeyLinkHorizontal,
  sankeyCenter,
} from "d3-sankey"
import { sliderRight } from "d3-simple-slider"

const Barchart = D3blackbox(function (anchor, props) {
  // const width = d3.min([window.innerWidth * 0.9, 1200])
  const data = props.data

  const width = 1100
  let dimensions = {
    width: width,
    height: 500,
    margin: {
      top: width / 20,
      right: 115,
      bottom: -40,
      left: 115,
    },
    sankey: {
      nodeWidth: 20,
      nodePadding: 5,
    },
  }
  dimensions.boundedHeight =
    dimensions.height - dimensions.margin.top - dimensions.margin.bottom
  dimensions.boundedWidth =
    dimensions.width - dimensions.margin.right - dimensions.margin.left

  const wrapper = d3
    .select(anchor.current)
    .append("svg")
    .attr("preserveAspectRatio", "xMinYMin meet")
    .attr("viewBox", `0 0 ${dimensions.width} ${dimensions.height}`)
    // Class to make it responsive.
    .attr("class", "wrapper")
  // .attr("width", dimensions.width)
  // .attr("height", dimensions.height + 100)

  const bounds = wrapper
    .append("g")
    .attr("class", "sankeyBounds")
    .attr(
      "transform",
      `translate(${dimensions.margin.left}, ${dimensions.margin.top})`
    )

  const region = data.allRegionCsv.nodes
  const family = data.allFamilyTidyCsv.nodes
  const lawson = data.allLawsonTidyCsv.nodes
  const seven = data.allSevenTidyCsv.nodes

  const dataset = d3.merge([family, lawson, seven]).map(d => {
    d["numStore"] = parseInt(d.numStore, 10) || 0
    d["region"] = region.filter(region => region.pref == d.pref)[0].region
    return d
  })
  const storeList = ["family", "seven", "lawson"]
  const storeNameJP = {
    family: "ファミリーマート",
    lawson: "ローソン",
    seven: "セブンイレブン",
  }
  const regionList = [
    "hokkaido",
    "tohoku",
    "kanto",
    "chubu",
    "kinki",
    "chugoku",
    "shikoku",
    "kyusyu",
  ]
  const regionNameJP = {
    hokkaido: "北海道地方",
    tohoku: "東北地方",
    kanto: "関東地方",
    chubu: "中部地方",
    kinki: "近畿地方",
    chugoku: "中国地方",
    shikoku: "四国地方",
    kyusyu: "九州地方",
  }
  const years = dataset.map(d => d.year).filter((v, i, a) => a.indexOf(v) === i)

  const maxNumStoreWithinYears = d3.max(
    years.map(year => {
      const datasetTargetYear = dataset.filter(d => d.year == year)
      let sumStore = 0
      datasetTargetYear.forEach(d => (sumStore += d.numStore))
      return sumStore
    })
  )

  const sankeyHeightScale = d3
    .scaleLinear()
    .domain([0, maxNumStoreWithinYears])
    .range([0, 1])

  const maxNumStoreWithinYearsBar = bounds
    .selectAll(".maxBar")
    .data([maxNumStoreWithinYears])
    .enter()
    .append("rect")
    .attr("class", "maxBar")
    .attr("x", -5)
    .attr("y", 0)
    .attr(
      "height",
      dimensions.boundedHeight -
        dimensions.sankey.nodePadding *
          Math.abs(storeList.length - regionList.length)
    )
    .attr("width", dimensions.sankey.nodeWidth + 10)
    .attr("fill", "gray")
  // .style("opacity", 0);

  bounds.selectAll(".maxNumBarTexts").remove()

  const maxNumBarTexts = bounds
    .append("g")
    .attr("class", "maxNumBarTexts")
    .style("opacity", 1)
  const maxNumBarTextOne = maxNumBarTexts
    .append("text")
    .attr("class", "maxNumBarText")
    .text("最大店舗数")
    .style(
      "transform",
      `translate(${-5 + (dimensions.sankey.nodeWidth + 10) / 2}px, ${
        dimensions.boundedHeight -
        dimensions.sankey.nodePadding *
          Math.abs(storeList.length - regionList.length) +
        20
      }px)`
    )
  const maxNumBarTextTwo = maxNumBarTexts
    .append("text")
    .attr("class", "maxNumBarTextTwo")
    .text(maxNumStoreWithinYears + "店 (2021)")
    .style(
      "transform",
      `translate(${-5 + (dimensions.sankey.nodeWidth + 10) / 2}px, ${
        dimensions.boundedHeight -
        dimensions.sankey.nodePadding *
          Math.abs(storeList.length - regionList.length) +
        40
      }px)`
    )

  function drawSankey(targetYear) {
    let defs = wrapper.append("defs")
    // const targetYear = years.sort()[yearCounter % years.length];
    // // const targetYear = years[0];
    // yearCounter = yearCounter + 1;

    bounds.selectAll(".yearTitle").remove()
    const yearTitle = bounds
      .selectAll(".yearTitle")
      .data([targetYear])
      .enter()
      .append("text")
      .attr("class", "yearTitle")
      .style(
        "transform",
        `translate(${dimensions.boundedWidth / 2}px, ${
          dimensions.margin.top - 50
        }px)`
      )
      .style("text-anchor", "middle")
      .text(`全国コンビニ店舗分布 ${targetYear.slice(1)}年`)

    const datasetThisYear = dataset.filter(d => d.year == targetYear)
    let numStoreThisYear = 0

    datasetThisYear.forEach(d => {
      numStoreThisYear += d.numStore
    })

    let numEachStoreThisYear = {}
    storeList.forEach(store => {
      numEachStoreThisYear[store] = d3.sum(
        datasetThisYear.filter(d => d.store == store).map(d => d.numStore)
      )
    })

    let numEachRegionThisYear = {}
    regionList.forEach(region => {
      numEachRegionThisYear[region] = d3.sum(
        datasetThisYear.filter(d => d.region == region).map(d => d.numStore)
      )
    })

    let originNodeTotalLength =
      sankeyHeightScale(numStoreThisYear) * dimensions.boundedHeight -
      dimensions.sankey.nodePadding *
        Math.abs(storeList.length - regionList.length)

    const restHalf =
      (dimensions.boundedHeight -
        dimensions.boundedHeight * sankeyHeightScale(numStoreThisYear)) /
      2

    bounds.selectAll(".ratioLine").remove()

    const ratioLine = bounds
      .append("g")
      .attr("transform", `translate(0, ${restHalf})`)

    wrapper.selectAll(".ratioLineText1").remove()
    wrapper.selectAll(".ratioLineText2").remove()
    ratioLine
      .append("line")
      .attr("x1", -15)
      .attr("x2", -15)
      .attr("y1", 0)
      .attr("y2", originNodeTotalLength)
      .attr("stroke", "black")
      .attr("class", "ratioLine")

    ratioLine
      .append("line")
      .attr("x1", -10)
      .attr("x2", -20)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "black")
      .attr("class", "ratioLine")

    ratioLine
      .append("line")
      .attr("x1", -10)
      .attr("x2", -20)
      .attr("y1", originNodeTotalLength)
      .attr("y2", originNodeTotalLength)
      .attr("stroke", "black")
      .attr("class", "ratioLine")

    ratioLine
      .append("text")
      .attr("x", -60)
      .attr("y", originNodeTotalLength / 2)
      .attr("class", "ratioLineText1")
      .text(numStoreThisYear + "店")

    ratioLine
      .append("text")
      .attr("x", -60)
      .attr("y", originNodeTotalLength / 2 + 20)
      .attr("class", "ratioLineText2")
      .text(`(${d3.format(".0%")(numStoreThisYear / maxNumStoreWithinYears)})`)

    let sankeyData = {}
    const nodesList = d3.merge([storeList, regionList])
    sankeyData["nodes"] = nodesList.map(node => ({
      id: node,
    }))
    sankeyData["links"] = d3.merge(
      storeList.map(store => {
        const pairedData = regionList.map(region => ({
          source: store,
          target: region,
          value: d3.sum(
            dataset
              .filter(d => d.region == region && d.store == store)
              .map(d => d.numStore)
          ),
        }))
        return pairedData
      })
    )

    const sankeyGen = d3Sankey()
      .size([
        dimensions.boundedWidth,
        sankeyHeightScale(numStoreThisYear) * dimensions.boundedHeight,
      ])
      .nodeId(d => d.id)
      .nodeWidth(dimensions.sankey.nodeWidth)
      .nodePadding(dimensions.sankey.nodePadding)
      .nodeSort(d => d.id)
      // // .linkSort((d) => d.id)
      .nodeAlign(sankeyCenter)

    let { nodes, links } = sankeyGen(sankeyData)

    const colorOriginScale = d3
      .scaleOrdinal()
      .domain(storeList)
      .range(["green", "orange", "blue"])

    const colorDestinationScale = d3
      .scaleOrdinal()
      .domain(regionList)
      .range([
        "#606d5d",
        "#d1f0b1",
        "#bbd5ed",
        "#4f6367",
        "#8c7064",
        "#c97d60",
        "#ffbcb5",
        "#aa1155",
      ])

    bounds.selectAll(".node").remove()

    const sankeyNodes = bounds
      .append("g")
      .attr("stroke", "#000")
      .selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", `translate(0, ${restHalf})`)

    const sankeyNodeRect = sankeyNodes
      .append("rect")
      .attr("class", "node-rect")
      .attr("x", d => d.x0)
      .attr("y", d => d.y0)
      .attr("height", d => d.y1 - d.y0)
      .attr("width", d => d.x1 - d.x0)
      .attr("fill", (d, idx) => {
        return idx <= storeList.length - 1
          ? colorOriginScale(d.id)
          : colorDestinationScale(d.id)
      })
    // .append("title")
    // .text((d) => `${format(d.id)}`);

    sankeyNodes
      .selectAll(".node-rect")
      .on("mouseenter", onSankeyNodeEnter)
      .on("mouseleave", onSankeyNodeLeave)

    const ratioLabels = bounds
      .append("g")
      .attr("class", "ratio-labels")
      .attr("transform", `translate(0, ${restHalf})`)

    let storeRatios = {}
    storeList.forEach(store => {
      const ratiosWithinStore = regionList.map(region => {
        const numStoreRegionStoreThisYear = d3.sum(
          datasetThisYear
            .filter(d => d.region == region && d.store == store)
            .map(d => d.numStore)
        )
        return numStoreRegionStoreThisYear / numEachStoreThisYear[store]
      })
      storeRatios[store] = ratiosWithinStore
    })
    let storeSums = {}
    storeList.forEach(store => {
      const ratiosWithinStore = regionList.map(region => {
        const numStoreRegionStoreThisYear = d3.sum(
          datasetThisYear
            .filter(d => d.region == region && d.store == store)
            .map(d => d.numStore)
        )
        return numStoreRegionStoreThisYear
      })
      storeSums[store] = ratiosWithinStore
    })

    const storeSum = ratioLabels
      .selectAll(".regionSum")
      .data(Object.entries(storeSums))
      .enter()
      .append("text")
      .attr("class", "ratio-labels sumLabel")
      .attr("x", -70)
      .attr("y", d => {
        const targetNode = nodes.filter(dd => dd.id == d[0])[0]
        return (targetNode.y1 + targetNode.y0) / 2
      })
      .text(d => {
        return `${d3.sum(d[1])}店`
      })
      .style("opacity", 0)

    let storeRatioLabels = {}
    storeList.forEach(store => {
      storeRatioLabels[store] = ratioLabels
        .selectAll(`.${store}-ratio-labels`)
        .data(nodes.filter(d => d.index > storeList.length - 1))
        .enter()
        .append("g")
        .attr("class", `.${store}-ratio-labels`)
        .append("text")
        .attr("class", "store-ratio-label")
        .text(
          (d, idx) =>
            `${storeSums[store][idx]}店 (${d3.format(".0%")(
              storeRatios[store][idx]
            )})`
        )
        .attr("y", d => (d.y1 + d.y0) / 2)
        .attr("x", dimensions.boundedWidth + 10)
        .style("opacity", 0)
    })

    let regionRatios = {}
    regionList.forEach(region => {
      const ratiosWithinRegion = storeList.map(store => {
        const numStoreRegionStoreThisYear = d3.sum(
          datasetThisYear
            .filter(d => d.region == region && d.store == store)
            .map(d => d.numStore)
        )
        return numStoreRegionStoreThisYear / numEachRegionThisYear[region]
      })
      regionRatios[region] = ratiosWithinRegion
    })

    let regionSums = {}
    regionList.forEach(region => {
      const ratiosWithinRegion = storeList.map(store => {
        const numStoreRegionStoreThisYear = d3.sum(
          datasetThisYear
            .filter(d => d.region == region && d.store == store)
            .map(d => d.numStore)
        )
        return numStoreRegionStoreThisYear
      })
      regionSums[region] = ratiosWithinRegion
    })

    let regionRatioLabels = {}
    regionList.forEach(region => {
      regionRatioLabels[region] = ratioLabels
        .selectAll(`.${region}-ratio-label`)
        .data(nodes.filter(d => d.index <= storeList.length - 1))
        .enter()
        .append("g")
        .attr("class", `.${region}-ratio-labels`)
        .append("text")
        .attr("class", "region-ratio-label")
        .text(
          (d, idx) =>
            `${regionSums[region][idx]}店 (${d3.format(".0%")(
              regionRatios[region][idx]
            )})`
        )
        .attr("y", d => (d.y1 + d.y0) / 2)
        .attr("x", -10)
        .style("opacity", 0)
    })

    const regionSum = ratioLabels
      .selectAll(".regionSum")
      .data(Object.entries(regionSums))
      .enter()
      .append("text")
      .attr("class", "regionSum sumLabel")
      .attr("x", dimensions.boundedWidth + 10)
      .attr("y", d => {
        const targetNode = nodes.filter(dd => dd.id == d[0])[0]
        return (targetNode.y1 + targetNode.y0) / 2
      })
      .text(d => {
        return `${d3.sum(d[1])}店`
      })
      .style("opacity", 0)

    bounds.selectAll(".nodeLabel").remove()

    const nodeLabels = bounds
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .selectAll(".nodeLabel")
      .data(nodes)
      .enter()
      .append("text")
      .attr("class", "nodeLabel")
      .attr("x", d => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
      .attr("y", d => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", d => (d.x0 < width / 2 ? "start" : "end"))
      .text((d, idx) =>
        idx <= storeList.length - 1 ? storeNameJP[d.id] : regionNameJP[d.id]
      )
      .attr("transform", `translate(0, ${restHalf})`)

    bounds.selectAll(".link").remove()
    const sankeyLinks = bounds
      .append("g")
      .attr("fill", "none")
      .attr("stroke-opacity", 0.5)
      .selectAll(".link")
      .data(links)
      .enter()
      .append("g")
      .style("mix-blend-mode", "multiply")
      .append("path")
      .attr("class", "link")
      .attr("d", sankeyLinkHorizontal())
      .attr("stroke-width", d => Math.max(1, d.width))
      .style("opacity", 1)
      .attr("transform", `translate(0, ${restHalf})`)

    sankeyLinks.style("stroke", (d, i) => {
      const gradientID = `gradient${i}`
      const startColor = colorOriginScale(d.source.id)
      const stopColor = colorDestinationScale(d.target.id)
      const linearGradient = defs
        .append("linearGradient")
        .attr("id", gradientID)
      linearGradient
        .selectAll("stop")
        .data([
          { offset: "10%", color: startColor },
          { offset: "90%", color: stopColor },
        ])
        .enter()
        .append("stop")
        .attr("offset", d => {
          return d.offset
        })
        .attr("stop-color", d => {
          return d.color
        })
      return `url(#${gradientID})`
    })
    function onSankeyNodeEnter(event, nodeDatum) {
      sankeyNodeRect.attr("fill", (d, idx) =>
        d.id == nodeDatum.id && idx <= storeList.length - 1
          ? colorOriginScale(d.id)
          : d.id == nodeDatum.id && idx > storeList.length - 1
          ? colorDestinationScale(d.id)
          : "#e8e8e8"
      )
      sankeyLinks.style("stroke", (d, i) => {
        const gradientID = `gradient${i}`

        if (nodeDatum.index <= storeList.length - 1) {
          return d.source.id == nodeDatum.id
            ? colorOriginScale(nodeDatum.id)
            : "#e8e8e8"
        } else {
          return d.target.id == nodeDatum.id
            ? colorDestinationScale(nodeDatum.id)
            : "#e8e8e8"
        }
      })
      ratioLine.style("opacity", 0)
      maxNumStoreWithinYearsBar.style("opacity", 0)
      if (nodeDatum.index <= storeList.length - 1) {
        storeRatioLabels[nodeDatum.id].style("opacity", 1)
        // storeSumLabels[nodeDatum.id].style("opacity", 1)
      } else {
        regionRatioLabels[nodeDatum.id].style("opacity", 1)
        // regionSumLabels[nodeDatum.id].style("opacity", 1)
      }
      maxNumBarTexts.style("opacity", 0)

      // nodeLabels.style("opacity", 0)
      gTime.style("opacity", 0)

      regionSum.filter(d => d[0] == nodeDatum.id).style("opacity", 1)
      storeSum.filter(d => d[0] == nodeDatum.id).style("opacity", 1)
    }
    function onSankeyNodeLeave(event, nodeDatum) {
      sankeyNodeRect.attr("fill", (d, idx) =>
        idx <= storeList.length - 1
          ? colorOriginScale(d.id)
          : colorDestinationScale(d.id)
      )

      sankeyLinks.style("stroke", (d, i) => {
        const gradientID = `gradient${i}`
        return `url(#${gradientID})`
      })
      ratioLine.style("opacity", 1)
      maxNumStoreWithinYearsBar.style("opacity", 1)

      Object.values(storeRatioLabels).forEach(v => v.style("opacity", 0))
      Object.values(regionRatioLabels).forEach(v => v.style("opacity", 0))
      maxNumBarTexts.style("opacity", 1)
      nodeLabels.style("opacity", 1)
      gTime.style("opacity", 1)

      regionSum.style("opacity", 0)
      storeSum.style("opacity", 0)
    }
  }

  let targetYear = "y2016"
  drawSankey(targetYear)

  var dataTime = d3.range(0, 12).map(function (d) {
    return new Date(2010 + d, 10, 3)
  })

  var sliderTime = sliderRight()
    .min(d3.min(dataTime))
    .max(d3.max(dataTime))
    .step(1000 * 60 * 60 * 24 * 365)
    .height(dimensions.boundedHeight)
    .tickFormat(d3.timeFormat("%Y"))
    .tickValues(dataTime)
    .default(new Date(2016, 10, 3))
    .on("onchange", val => {
      d3.select("p#value-time").text(d3.timeFormat("%Y")(val))
      targetYear = "y" + val.getFullYear()
      drawSankey(targetYear)
    })

  var gTime = bounds
    // .attr("width", dimensions.margin.left + dimensions.boundedWidth / 2)
    // .attr("height", 100)
    .append("g")
    .style(
      "transform",
      `translate(${dimensions.boundedWidth + dimensions.margin.left - 70}px, ${
        dimensions.margin.top - 40
      }px)`
    )

  gTime.call(sliderTime)
})

export default Barchart
